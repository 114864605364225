import { CustomCellRendererProps } from "ag-grid-react";
import { IconButton } from "veronica-ui-component/dist/component/core";

export const cellRenderWithColorAndCircle = (params: CustomCellRendererProps) => {
    const fieldKey: string = params?.colDef?.field!;

    if (!fieldKey && params?.colDef?.cellRenderer) {
        return <>{params?.value}</>;
    } else if (!fieldKey && params?.value) {
        return <>{params?.value}</>;
    } else if (params.api.isPivotMode() && fieldKey && params?.column && params.column.getAggFunc()) {
        return <>{params?.getValue && params.formatValue && (params.getValue() ? params.formatValue(params.getValue().value) : <></>)}</>;
    }

    if (fieldKey && params.node.field && params.node.field !== fieldKey) {
        return <></>
    }

    const clsKey: string = fieldKey + 'Class';

    return <span className={`charge-detail-cell ${params.data[clsKey]}`}><span className={`circle ${params.data[clsKey]}`}></span>{params.data[fieldKey]}</span>;
}

export const cellRenderWithColorAndAlertIcon = (params: CustomCellRendererProps) => {
    const fieldKey: string = params?.colDef?.field!;
    if (!fieldKey && params?.colDef?.cellRenderer) {
        return <>{params?.value}</>;
    } else if (!fieldKey && params?.value) {
        return <>{params?.value}</>;
    } else if (params.api.isPivotMode() && fieldKey && params?.column && params.column.getAggFunc()) {
        return <>{params?.getValue && params?.getValue().value && params.formatValue && params.formatValue(params.getValue().value)}</>;
    }

    if (fieldKey && params.node.field && params.node.field !== fieldKey) {
        return <></>
    }

    const clsKey: string = fieldKey + 'Class';
    const tooltipKey: string = fieldKey + 'Tooltip';
    const listenerKey: string = fieldKey + 'Listener';

    return <span className='alter-icon-with-red'>
        <span className={`charge-detail-cell ${params.data[clsKey]}`}>{params.data[fieldKey]}</span>
        {
            (params.data[fieldKey] ?? 0) > 0 &&
            <IconButton fileName="Icon-alert" size="small" toolTipPlacement='left' tooltipDisable={false} toolTipText={params.data[tooltipKey]} onClick={params.data[listenerKey]} />
        }
    </span>
}

export const cellRenderWithNumberColorAndDocIcon = (params: CustomCellRendererProps) => {
    const fieldKey: string = params?.colDef?.field!;
    if (!fieldKey && params?.colDef?.cellRenderer) {
        return <>{params?.value}</>;
    } else if (!fieldKey && params?.value) {
        return <>{params?.value}</>;
    } else if (params.api.isPivotMode() && fieldKey && params?.column && params.column.getAggFunc()) {
        return <>{params?.getValue && params?.getValue().value && params.formatValue && params.formatValue(params.getValue().value)}</>;
    }

    if (fieldKey && params.node.field && params.node.field !== fieldKey) {
        return <></>
    }

    const clsKey: string = fieldKey + 'Class';
    const tooltipKey: string = fieldKey + 'Tooltip';
    const listenerKey: string = fieldKey + 'Listener';

    return <span className='detail-yard-complete'>
        <span className={`charge-detail-cell ${params.data[clsKey]}`}>{params.data[fieldKey]}</span>
        {
            (params.data[fieldKey] ?? 0) > 0 &&
            <IconButton fileName="Icon-doc" size="small" toolTipPlacement='top' tooltipDisable={false} toolTipText={params.data[tooltipKey]} onClick={params.data[listenerKey]} />
        }
    </span>
}

export const cellRenderWithStringColorAndDocIcon = (params: CustomCellRendererProps) => {
    const fieldKey: string = params?.colDef?.field!;
    if (!fieldKey && params?.colDef?.cellRenderer) {
        return <>{params?.value}</>;
    } else if (!fieldKey && params?.value) {
        return <>{params?.value}</>;
    } else if (params.api.isPivotMode() && fieldKey && params?.column && params.column.getAggFunc()) {
        return <>{params?.getValue && params?.getValue().value && params.formatValue && params.formatValue(params.getValue().value)}</>;
    }

    if (fieldKey && params.node.field && params.node.field !== fieldKey) {
        return <></>
    }

    const clsKey: string = fieldKey + 'Class';
    const tooltipKey: string = fieldKey + 'Tooltip';
    const listenerKey: string = fieldKey + 'Listener';

    return <span className='detail-yard-complete'>
        <span className={`charge-detail-cell ${params.data[clsKey]}`}>{params.data[fieldKey]}</span>
        {
            (params.data[fieldKey]) &&
            <IconButton fileName="Icon-doc" size="small" toolTipPlacement='top' tooltipDisable={false} toolTipText={params.data[tooltipKey]} onClick={params.data[listenerKey]} />
        }
    </span>
}


export const cellRenderWithColorAndTags = (params: CustomCellRendererProps) => {
    const fieldKey: string = params?.colDef?.field!;
    if (!fieldKey && params?.colDef?.cellRenderer) {
        return <>{params?.value}</>;
    } else if (!fieldKey && params?.value) {
        return <>{params?.value}</>;
    } else if (params.api.isPivotMode() && fieldKey && params?.column && params.column.getAggFunc()) {
        return <>{params?.getValue && params?.getValue().value && params.formatValue && params.formatValue(params.getValue().value)}</>;
    }

    if (fieldKey && params.node.field && params.node.field !== fieldKey) {
        return <></>
    }

    const clsKey: string = fieldKey + 'Class';

    return <span className={`charge-detail-cell`}><span className={`rectangles ${params.data[clsKey]}`}>{params.data[fieldKey]}</span></span>;
}

